import { Component, ViewChild } from '@angular/core';
import { PopoverController, AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { CommonService } from '../../../services/common.service';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';

import { TranslationService } from 'src/app/services/translation.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MatSelect } from '@angular/material/select';
import { NgForm } from '@angular/forms';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent {


  @ViewChild('domainChangeComponent', { static: false }) domainChangeComponent: IonicSelectableComponent;
  @ViewChild('domainSelect', { static: false }) domainSelect: MatSelect;
  @ViewChild('f', { read: NgForm }) form: any;

  domains: any = [];
  show: boolean = false;
  submitted: boolean = false;
  domainPopOver: any;
  linkedToMultipleDomain: boolean = false
  isSSO: boolean = false;

  constructor(public popoverCtrl: PopoverController, public modalController: ModalController, public ngxSmartModalService: NgxSmartModalService, private router: Router, private _authService: AuthService, private _apiService: ApiService, private alertController: AlertController, public _commonService: CommonService, private _translationService: TranslationService) { }

  ngOnInit(): void {
    this.getDomains();
    this._commonService.openFromPopover = false;
    if (localStorage.isSSO && localStorage.isSSO == 'true') {
      this.isSSO = true;
    }
  }


  ngOnDestroy(): void {
  }

  async dismissPopover() {
    await this.popoverCtrl.dismiss();
  }

  logout() {
    this._authService.logout();
  }

  selectLocale() {
    this._commonService.openLocaleModel();
  }

  openChangeDomainModal() {
    this.domainChangeComponent.open();
  }

  routeToLicenseDetail() {
    this.router.navigate(['maintenance/domains/' + localStorage.domainId])
  }



  async getDomains() {
    try {
      if (localStorage.domains && localStorage.domains != 'null' && JSON.parse(localStorage.domains).length > 0) {
        this.domains = JSON.parse(localStorage.domains);
      } else {
        let domainsData = await this._apiService.getDomains('', '', '', 1, 0, false);
        this.domains = domainsData.body;
        localStorage.domains = JSON.stringify(domainsData.body);
      }
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }



  selectChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this._commonService.log(event.value, this._commonService.LOG);
  }

  changeDomain() {
    this.domainChangeComponent.confirm();
    this.domainChangeComponent.close();
    if (this._commonService.domainName.toLowerCase() != this.domainChangeComponent.value.Name.toLowerCase()) {
      this._commonService.presentLoading();
      let params = {
        LocaleCode: ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043),
        DomainId: this.domainChangeComponent.value.DomainId
      }
      this._authService.doDomainLogin(params, 'Login/Domain', localStorage.authenticationKey).subscribe(
        async (res) => {

          let authenticationKey = localStorage.authenticationKey;
          let menuItems = localStorage.menuItems;
          let domains = localStorage.domains;
          this._commonService.clearData();
          localStorage.authenticationKey = authenticationKey;
          localStorage.menuItems = menuItems;
          localStorage.domains = domains;

          this._authService.setLoginData(res);

        },
        (err) => {
          this._commonService.log(err, this._commonService.ERROR);
          this._commonService.dismiss();
        }
      );
    }
  }

  onSelect(event) {
    if (!event.isSelected) {
      this.domainChangeComponent._selectedItems.push(event.item);
    }
  }

  openUserInviteModal() {
    this._commonService.openFromPopover = true
    this.ngxSmartModalService.getModal('userInviteModal').open()
  }

}