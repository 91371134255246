import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-locale-popover',
  templateUrl: './locale-popover.component.html',
  styleUrls: ['./locale-popover.component.scss'],
})
export class LocalePopoverComponent implements OnInit {

  allLocales: any =[];

  constructor(
    public popoverCtrl: PopoverController,
    public _commonService: CommonService,
    private _apiService: ApiService,
    private _translationService: TranslationService,
  ) {
   }

  async ngOnInit() {
    await this.getLocales();
  }

  async changeLocale(item){

    try {
      this._commonService.presentLoading();

      localStorage.locale = item.LocaleId;
      this._commonService.locale = item.LocaleId; 
      await this._translationService.getTranslation(item.LocaleId);

      this._commonService.dismiss();

    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);

    }


  }
  
  async dismiss() {
    await this.popoverCtrl.dismiss();
  }

  async getLocales() {
    try {
      if (localStorage.domainLocales) {
        this.allLocales = JSON.parse(localStorage.domainLocales);
        for (let i = 0; i < this.allLocales.length; i++) {
          
          if (this.allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
            this.allLocales[i].Icon = 'assets/flags/1x1/nl.svg';
            this.allLocales[i].LocaleTranslationCode = 'label-netherlandsLanguage';
            this.allLocales[i].TranslatedText = this._commonService.getLocalTranslation('label-netherlandsLanguage');

          }
          if (this.allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
            this.allLocales[i].Icon = 'assets/flags/1x1/gb.svg';
            this.allLocales[i].LocaleTranslationCode = 'label-englishLanguage';
            this.allLocales[i].TranslatedText = this._commonService.getLocalTranslation('label-englishLanguage');
          }
        }

      } else {
        let localeData = await this._apiService.getDomainLocales('', 1, 0);
        this.allLocales = localeData.body;
        localStorage.domainLocales = JSON.stringify(localeData.body);
        for (let i = 0; i < this.allLocales.length; i++) {
          if (this.allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
            this.allLocales[i].Icon = 'assets/flags/1x1/nl.svg';
            this.allLocales[i].LocaleTranslationCode = 'label-netherlandsLanguage';
            this.allLocales[i].TranslatedText = this._commonService.getLocalTranslation('label-netherlandsLanguage');
          }
          if (this.allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
            this.allLocales[i].Icon = 'assets/flags/1x1/gb.svg';
            this.allLocales[i].LocaleTranslationCode = 'label-englishLanguage';
            this.allLocales[i].TranslatedText = this._commonService.getLocalTranslation('label-englishLanguage');

          }
        }
      }

      this.allLocales.sort((a,b)=> {
        if(a.TranslatedText < b.TranslatedText) return -1;
        if(a.TranslatedText > b.TranslatedText) return 1;
        return 0;
      });
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }
}
