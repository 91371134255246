import { Component, OnInit, ViewChild, NgZone, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { PopoverController, MenuController, IonSlides, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ProfilePopoverComponent } from '../profile-popover/profile-popover.component';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { TranslationService } from 'src/app/services/translation.service';
import { LocalePopoverComponent } from '../locale-popover/locale-popover.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('IonSlides', { static: true }) slider: IonSlides;
  @Output() backEvent = new EventEmitter<string>();
  @Input() sideNavOpened: boolean;

  allLocales: any = [];
  currentLocaleFlag: any;
  currentLocaleCode: any;
  langChangeSubscription: Subscription;

  defaultLocales = [
    { LocaleCode: "EN", Locale: "English", LocaleId: 1033, LocaleIconUrl: "flag-icon-gb" },
    { LocaleCode: "NL", Locale: "Dutch", LocaleId: 1043, LocaleIconUrl: "flag-icon-nl" }
  ];



  @HostListener('click', ['$event'])
  onClick(e) {
    if (!e.target.className.includes('header-close-icon') && !e.target.className.includes('header-book-icon')) {
      this.backEvent.next('directClosed');
    }
  }
  constructor(private router: Router, private _translate: TranslateService, private _translationService: TranslationService, private alertController: AlertController, private ngZone: NgZone, private _mainService: MainService, private popoverController: PopoverController, private _apiService: ApiService, public _commonService: CommonService, public menuCtrl: MenuController, private _authService: AuthService) {


    this.langChangeSubscription = this._translate.onLangChange.subscribe((data) => {
      let currentLocaleItem = this.allLocales.find(el => el.LocaleId == data.lang);
      this.currentLocaleFlag = currentLocaleItem.Icon;
      this.currentLocaleCode = currentLocaleItem.LocaleCode;
    })

  }

  slideOptsOne: {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
  }

  async ngOnInit() {

    if (!this._commonService.isLoggedIn) {



    }

    if (localStorage.domainLocales && localStorage.domainLocales != "undefined") {
      this.defaultLocales = JSON.parse(localStorage.domainLocales);
    }

    await this.getLocales();
    let currentLocaleItem = this.allLocales.find(el => el.LocaleId == this._commonService.locale);
    this.currentLocaleFlag = currentLocaleItem.Icon;
    this.currentLocaleCode = currentLocaleItem.LocaleCode;

  }




  async presentLocalePopover(event: any) {
    const popover = await this.popoverController.create({
      component: LocalePopoverComponent,
      event: event,
      showBackdrop: false,
      cssClass: "localePopOver",
      translucent: true,
    });

    return await popover.present();
  }

  slidesDidLoad(slides: IonSlides) {
    slides.startAutoplay();
  }

  async presentProfilePopover(event: any) {
    const popover = await this.popoverController.create({
      component: ProfilePopoverComponent,
      event: event,
      showBackdrop: false,
      cssClass: 'popPopOver',
      translucent: true
    });
    return await popover.present();
  }


  toggleMenu() {
    this.menuCtrl.toggle();
    if (window.innerWidth > 800) {
      //this._commonService.showSideMenu = !this._commonService.showSideMenu;
      this._commonService.showIconOnly = !this._commonService.showIconOnly
      this._commonService.onToggle.emit("toggled");
      if (this._commonService.showSideMenu) this._commonService.customActivityListSelectOptions.cssClass = "custom-select-interface"
      else this._commonService.customActivityListSelectOptions.cssClass = ""
    } else {
      this.menuCtrl.toggle();
    }

  }

  logout() {
    this._authService.logout();
  }
  goMessage() {
    this.router.navigate(['/notifications']);
  }
  goSetting() {
    this.router.navigate(['/maintenance']);
  }
  goProfile() {
    this.router.navigate(['/profile']);
  }

  goto() {


    if (this._commonService.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  hyperlinks(type) {
    let url;
    if (type == "email") {
      url = 'mailto:' + 'TODO';
      window.open(url, '_blank');
    } else if (type == "TODO") {
      url = 'tel:+' + '';
      window.open(url, '_blank');
    } else if (type != "email" && type != "phone") {
      url = type;
      window.open(url, '_blank');
    }
  }

  menuOpened() {
    setTimeout(() => {

      let radio = document.querySelectorAll('ion-radio');
      if (radio) {

        for (var i = 0; i < radio.length; i++) {
          let radioItem = radio[i];
          radioItem.shadowRoot.querySelector('.radio-icon').setAttribute('style', 'border: none;box-shadow: 0px 3px 0 0 #dfdfdf;background-color: #ffffff;curson:pointer');
          if (radioItem.className.includes('radio-checked')) {
            radioItem.shadowRoot.querySelector('.radio-inner').setAttribute('style', 'transform: scale3d(0.8, 0.8, 0.8);');
          } else {
            radioItem.shadowRoot.querySelector('.radio-inner').setAttribute('style', 'transform: scale3d(0, 0, 0);');
          }

        }

      }

      // this.dismiss();
    }, 200);
  }

  async onDomainChange(event) {
    let domain = this._commonService.domains.find(f => f.DomainId == event).Name
    const alert = await this.alertController.create({
      header: this._commonService.getLocalTranslation(''),
      message: this._commonService.getLocalTranslation('label-domainChange'),
      buttons: [
        {
          text: this._commonService.getLocalTranslation('label-ok'),
          handler: () => {
            //  logout the user
            this._commonService.presentLoading();
            let params = {
              LocaleCode: ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043),
              DomainName: domain
            }

            this._authService.doDomainLogin(params, 'Login/Domain', localStorage.authenticationKey).subscribe(
              async (res) => {

                let authenticationKey = localStorage.authenticationKey;
                let menuItems = localStorage.menuItems;
                let domains = localStorage.domains;
                this._commonService.clearData();
                localStorage.authenticationKey = authenticationKey;
                localStorage.menuItems = menuItems;
                localStorage.domains = domains;

                localStorage.setItem('userId', res.UserId);
                localStorage.setItem('userName', res.UserName);
                localStorage.setItem('roleCode', res.RoleCode);
                localStorage.setItem('domainLocales', JSON.stringify(res.Locales));
                localStorage.setItem('menuItems', JSON.stringify(res.MenuItems));
                localStorage.setItem('domainName', res.DomainName);
                localStorage.setItem('domainId', res.DomainId);
                
    
      
                
                localStorage.setItem('Datecreated', res.body.Datecreated ? res.body.Datecreated : '');
                localStorage.setItem('FirstLogin', res.body.FirstLogin ? res.body.FirstLogin : 'false');


                if (res.Companies) localStorage.setItem('companies', JSON.stringify(res.Companies));
                localStorage.setItem('userRoles', res.UserRoles ? JSON.stringify(res.UserRoles) : null);


                this._commonService.domainLocales = res.Locales;
                if (res.Locales.length == 1) {
                  this._commonService.locale = res.Locales[0].LocaleId;
                  localStorage.locale = res.Locales[0].LocaleId;
                }

                this._commonService.formatMenuItems();

                this._translationService.getTranslation(localStorage.locale);

                this._commonService.domainName = res.DomainName;
                this._commonService.domain = {
                  DomainId: res.DomainId,
                  Name: res.DomainName
                }



                localStorage.domains = res.LinkedDomains ? JSON.stringify(res.LinkedDomains) : null;

                if (this.router.url === '/dashboard') {
                  this._commonService.onDomainChange.emit("domainChanged");
                }
                else {
                  this.router.navigate(['/dashboard'], { replaceUrl: true });
                }
                this._commonService.dismiss();

              },
              (err) => {
                this._commonService.log(err, this._commonService.ERROR);
                this._commonService.dismiss();
              }
            );
          }
        },
        {
          text: this._commonService.getLocalTranslation('label-cancel'),
          role: 'cancel',
          handler: () => {
          }
        }


      ]
    });
    await alert.present();

  }

  knowledgeBasePopOver(event) {
    //this._commonService.presentAlert('', this._commonService.getLocalTranslation('label-knowledgeCenterComigSoon'))
    this.backEvent.next(event);
  }

  async getLocales() {
    try {

      if (localStorage.domainLocales && localStorage.domainLocales != 'undefined') {
        this.allLocales = JSON.parse(localStorage.domainLocales);
        for (let i = 0; i < this.allLocales.length; i++) {
          if (this.allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
            this.allLocales[i].Icon = 'assets/flags/1x1/nl.svg'
          }
          if (this.allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
            this.allLocales[i].Icon = 'assets/flags/1x1/gb.svg'
          }
        }
      }
      else {
        if (this._commonService.isLoggedIn) {
          let localeData = await this._apiService.getDomainLocales('', 1, 0);
          this.allLocales = localeData.body;
          localStorage.domainLocales = JSON.stringify(localeData.body);
          this._commonService.domainLocales = localeData.body;
          for (let i = 0; i < this.allLocales.length; i++) {
            if (this.allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
              this.allLocales[i].Icon = 'assets/flags/1x1/nl.svg'
            }
            if (this.allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
              this.allLocales[i].Icon = 'assets/flags/1x1/gb.svg'
            }
          }
        } else {

          localStorage.domainLocales = JSON.stringify(this.defaultLocales);
          this._commonService.domainLocales = this.defaultLocales;
          this.allLocales = this.defaultLocales;

          for (let i = 0; i < this.allLocales.length; i++) {
            if (this.allLocales[i].LocaleIconUrl == 'flag-icon-nl') {
              this.allLocales[i].Icon = 'assets/flags/1x1/nl.svg'
            }
            if (this.allLocales[i].LocaleIconUrl == 'flag-icon-gb') {
              this.allLocales[i].Icon = 'assets/flags/1x1/gb.svg'
            }
          }

        }
      }


    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }

}