import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public _commonService: CommonService, private _authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const allowedDomains = next.data.allowedDomains;
    const isAuthorized = this._authService.isAuthorized(allowedDomains);

    if((localStorage.authenticationKey) && (isAuthorized)){
      this._commonService.isLoggedIn = true;
      if (!window.performance.navigation.type && state.root.children[0].url.length > 3 &&(state.root.children[0].url[0].path == 'documentlibrary' && (state.root.children[0].url[1].path == 'documents' || state.root.children[0].url[1].path == 'standarddocument'))){
        this._commonService.redirectURL = state.url;
        this._commonService.redirectDomainId = state.root.children[0].url[3].path;
      }
      let role = localStorage.roleCode ? localStorage.roleCode : '';
      let menuItems = localStorage.menuItems ? JSON.parse(localStorage.menuItems) : [];
      let url = '';
      if(role && role == "UserRole_Administrator") {
        if(state.url.includes("processingactivities")  || state.url.includes('/processingactivity')  || state.url.includes('/processingrecords')){
          this.router.navigate(['reservations']);
          return false;
        }else{
          return true;
        }

      } else {
        // if(state.url == "/processingactivities" || (state.url.indexOf('/processingactivity/') != -1) || (state.url.indexOf('/processingrecords/') != -1)){
        //   if(role && (role == "UserRole_CustomerAdmin" || role == "UserRole_CustomerUser")){
        //     return true;
        //   }else{
        //     this.router.navigate(['reservations']);
        //     return false;
        //   }
        // }
        // else {
        //   url = state.url;
        // }

        if(state.url == '/profile'){
          return true;
        } else {
          url = state.url;
        }

        let checkElement = this._commonService.findElement(menuItems, 'childMenuItems', 'Action', url);
        if(checkElement.length > 0) {
          return true;
        } 
        else if(url && url.includes('/maintenance/domains/')){
          if(role && (role == "UserRole_Support" || role == "UserRole_Administrator")){
            return true
          }else{
            this.router.navigate(['reservations']);
            return false;
          }
        }
        else if (url == '/profile'){
          return true;
        }
        else if(url == '/dashboard') {
          this.router.navigate(['login']);
          return false;
        } else {
          this.router.navigate(['reservations']);
          return false;
        }

      }
    }
    else if (this._commonService.tempAuthKey){
      return true;
    }
    else if (this._commonService.isRegister){
      return true;
    }
     
    else{
      if (!window.performance.navigation.type && state.root.children[0].url.length > 3 &&(state.root.children[0].url[0].path == 'documentlibrary' && (state.root.children[0].url[1].path == 'documents' || state.root.children[0].url[1].path == 'standarddocument'))){
        this._commonService.redirectURL = state.url;
        this._commonService.redirectDomainId = state.root.children[0].url[3].path;
      }
      this.router.navigate(['login']);
      return false;
    }
  }


}
