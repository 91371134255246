import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  url: string = null;

  constructor(private _httpClient: HttpClient) { }

  // GET
  callWebserviceHttpGet(webserviceApiName, authKey): Promise<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      observe: "response" as 'body'
    }
    return this._httpClient.get((this.url ? this.url : environment.url) + webserviceApiName, httpOptions).toPromise();
  }

  callWebserviceHttpGetForRegister(webserviceApiName): Promise<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-LoginSource': 'weboffice'
      }),
      observe: "response" as 'body'
    }
    return this._httpClient.get((this.url ? this.url : environment.url) + webserviceApiName, httpOptions).toPromise();
  }

  // GET FILE
  callWebserviceHttpGetFile(webserviceApiName, authKey): Promise<any> {
    return this._httpClient.get(this.url + webserviceApiName, {
      headers: new HttpHeaders({
        'Content-Type' : 'application/json',
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      responseType: 'blob', 
      observe: "response" as 'body',
    }).toPromise();
  }

  // GET FILE WITH POST
  callWebserviceHttpGetFileWithPost(webserviceApiName, data, authKey): Promise<any> {
    return this._httpClient.post(this.url + webserviceApiName, data, {
      headers: new HttpHeaders({
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      responseType: 'blob',
      observe: "response" as 'body',
    }).toPromise();
  }

  // GET without header
  callWebserviceHttpGetNoHeader(webserviceApiName, authKey): Promise<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.get((this.url ? this.url : environment.url) + webserviceApiName, { headers: headers }).toPromise();
  }

  // GET By Id
  callWebserviceHttpGetById(id, webserviceApiName, authKey): Promise<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.get((this.url ? this.url : environment.url) + webserviceApiName + '/' + id, { headers: headers }).toPromise();
  }

  // POST
  callWebserviceHttpPost(webserviceApiName, objParams, authKey) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.post((this.url ? this.url : environment.url) + webserviceApiName, objParams, { headers: headers }).toPromise();
  }
  
  callWebserviceHttpFormPost(webserviceApiName, objParams, authKey) {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      observe: "response" as 'body',
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
    }
    // let headers = new HttpHeaders({
    
    //   'X-Security-AuthKey': authKey ? authKey : '',
    //   'X-LoginSource': 'weboffice'
    
    // })
    return this._httpClient.post((this.url ? this.url : environment.url) + webserviceApiName, objParams, httpOptions).toPromise();
  }
  // PUT
  callWebserviceHttpPut(webserviceApiName, objParams, authKey, ) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.put((this.url ? this.url : environment.url) + webserviceApiName, objParams, { headers: headers }).toPromise();
  }

  // PUT WITH PASSWORD
  callWebserviceHttpPutWithPassword(webserviceApiName, objParams, password, authKey) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      'Authorization': password,
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.put((this.url ? this.url : environment.url) + webserviceApiName, objParams, { headers: headers }).toPromise();
  }

  // POST WITH PASSWORD
  callWebserviceHttpPostWithPassword(webserviceApiName, objParams, password, authKey) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      'Authorization': password,
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.post((this.url ? this.url : environment.url) + webserviceApiName, objParams, { headers: headers }).toPromise();
  }

  // PUT FILE
  callWebserviceHttpPutFile(webserviceApiName, objParams, authKey) {
    let headers = new HttpHeaders({
      'X-Security-AuthKey': authKey ? authKey : '',
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.put((this.url ? this.url : environment.url) + webserviceApiName, objParams, { headers: headers }).toPromise();
  }

  // PATCH
  callWebserviceHttpPatch(webserviceApiName, objParams, authKey) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
    })
    return this._httpClient.patch((this.url ? this.url : environment.url) + webserviceApiName, objParams, { headers: headers }).toPromise();
  }
  callWebserviceHttpFormPatch(webserviceApiName, objParams, authKey) {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      observe: "response" as 'body',
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
    }
    // let headers = new HttpHeaders({
    
    //   'X-Security-AuthKey': authKey ? authKey : '',
    //   'X-LoginSource': 'weboffice'
    
    // })
    return this._httpClient.patch((this.url ? this.url : environment.url) + webserviceApiName, objParams, httpOptions).toPromise();
  }

  callWebserviceHttpFormPut(webserviceApiName, objParams, authKey) {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      observe: "response" as 'body',
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
    }
    // let headers = new HttpHeaders({
    
    //   'X-Security-AuthKey': authKey ? authKey : '',
    //   'X-LoginSource': 'weboffice'
    
    // })
    return this._httpClient.put((this.url ? this.url : environment.url) + webserviceApiName, objParams, httpOptions).toPromise();
  }
  // DELETE
  callWebserviceHttpDelete(webserviceApiName, ids, authKey) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      })
    };
    return this._httpClient.delete((this.url ? this.url : environment.url) + webserviceApiName + ids + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), options).toPromise();
  }

    // DELETE
    callWebserviceHttpDeleteHeaderImage(webserviceApiName, ids, authKey) {
      let options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Security-AuthKey': authKey ? authKey : '',
          'X-LoginSource': 'weboffice'
        })
      };
      return this._httpClient.delete((this.url ? this.url : environment.url) + webserviceApiName + ids + "?IsHeader=true&localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), options).toPromise();
    }

// DELETE USER WITH PASSWORD
  callWebserviceHttpUserDelete(webserviceApiName, ids, password, authKey) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Security-AuthKey': authKey ? authKey : '',
        'Authorization': password,
        'X-LoginSource': 'weboffice'
      })
    };
    return this._httpClient.delete((this.url ? this.url : environment.url) + webserviceApiName + ids + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), options).toPromise();
  }


}